// styles.ts
import { makeStyles } from "tss-react/mui";
import { keyframes } from "@emotion/react";

const zoomIn = keyframes({
  "0%": {
    opacity: 0,
    transform: "scale3d(0.95, 0.95, 0.95)",
  },
  "100%": {
    opacity: 1,
    transform: "scale3d(1, 1, 1)",
  },
});

const lockPulse = keyframes({
  "0%": {
    transform: "scale(1)",
    backgroundColor: "#ff6b6b",
  },
  "50%": {
    transform: "scale(1.1)",
    backgroundColor: "#ff5252",
  },
  "100%": {
    transform: "scale(1)",
    backgroundColor: "#ff6b6b",
  },
});

const lockShake = keyframes({
  "0%": {
    transform: "rotate(0deg)",
    borderColor: "#ff6b6b",
  },
  "10%": {
    transform: "rotate(-10deg)",
    borderColor: "#ff5252",
  },
  "20%": {
    transform: "rotate(10deg)",
    borderColor: "#ff5252",
  },
  "30%": {
    transform: "rotate(-10deg)",
    borderColor: "#ff5252",
  },
  "40%": {
    transform: "rotate(0deg)",
    borderColor: "#ff6b6b",
  },
  "100%": {
    transform: "rotate(0deg)",
    borderColor: "#ff6b6b",
  },
});

const sparkle = keyframes({
  "0%": {
    opacity: 0,
    transform: "scale(0)",
  },
  "50%": {
    opacity: 1,
    transform: "scale(1)",
  },
  "100%": {
    opacity: 0,
    transform: "scale(0)",
  },
});

export const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    margin: 0,
    marginRight: 8,
    fontSize: 14,
    color: "#333",
    fontFamily: "'Montserrat', sans-serif",
  },

  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  modalContainer: {
    position: "relative",
    width: 450,
    background: "linear-gradient(135deg, #ffffff 0%, #f5f5f5 100%)",
    borderRadius: 16,
    overflow: "hidden",
    boxShadow: "0 20px 50px rgba(0, 0, 0, 0.3)",
    animation: `${zoomIn} 0.4s cubic-bezier(0.165, 0.84, 0.44, 1)`,
  },
  modalBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(135deg, rgba(255, 107, 107, 0.05) 0%, rgba(255, 107, 107, 0.1) 100%)",
    zIndex: 1,
  },
  modalContent: {
    position: "relative",
    zIndex: 2,
  },
  modalHeader: {
    padding: "30px 30px 15px",
    textAlign: "center",
  },

  // Lock animation
  lockAnimation: {
    width: 60,
    height: 60,
    margin: "0 auto 15px",
    position: "relative",
  },
  lockBody: {
    width: 30,
    height: 25,
    backgroundColor: "#ff6b6b",
    margin: "15px auto 0",
    borderRadius: 5,
    position: "relative",
    animation: `${lockPulse} 3s infinite`,

    "&::after": {
      content: '""',
      position: "absolute",
      top: -15,
      left: 5,
      width: 20,
      height: 20,
      borderRadius: "50px 50px 0 0",
      border: "5px solid #ff6b6b",
      borderBottom: "none",
      animation: `${lockShake} 3s infinite`,
    },
  },

  // Sparkles
  sparkle: {
    position: "absolute",
    backgroundColor: "#ffb347",
    borderRadius: "50%",
  },
  sparkle1: {
    width: 8,
    height: 8,
    top: 10,
    right: 5,
    animation: `${sparkle} 3s infinite 0.2s`,
  },
  sparkle2: {
    width: 5,
    height: 5,
    bottom: 5,
    left: 5,
    animation: `${sparkle} 3s infinite 0.5s`,
  },
  sparkle3: {
    width: 6,
    height: 6,
    top: 20,
    left: 15,
    animation: `${sparkle} 3s infinite 0.8s`,
  },

  modalTitle: {
    fontSize: 22,
    fontWeight: 700,
    color: "#333",
    margin: "0 0 5px",
  },
  modalSubtitle: {
    fontSize: 14,
    color: "#666",
    margin: "0 0 15px",
  },
  modalDivider: {
    width: 60,
    height: 3,
    backgroundColor: "#ff6b6b",
    margin: "0 auto 20px",
    borderRadius: 2,
  },
  modalBody: {
    padding: "0 30px 20px",
  },

  featureHighlight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 107, 107, 0.1)",
    borderRadius: 10,
    padding: 20,
    marginBottom: 20,
  },
  featureIcon: {
    color: "#ff6b6b",
    fontSize: 24,
    marginRight: 15,
  },
  featureText: {
    flex: 1,
    fontSize: 15,
    fontWeight: 500,
    color: "#444",
  },
  premiumBadge: {
    display: "inline-block",
    background: "linear-gradient(135deg, #ff6b6b 0%, #ff9d9d 100%)",
    color: "white",
    fontSize: 11,
    fontWeight: 600,
    padding: "3px 8px",
    borderRadius: 4,
    marginLeft: 8,
    boxShadow: "0 2px 5px rgba(255, 107, 107, 0.3)",
  },
  modalMessage: {
    textAlign: "center",
    fontSize: 15,
    color: "#666",
    marginBottom: 25,
    lineHeight: 1.6,
  },
  highlightedText: {
    color: "#ff6b6b",
    fontWeight: 600,
  },
  modalActions: {
    display: "flex",
    gap: 15,
  },

  // Buttons
  btn: {
    flex: 1,
    border: "none",
    borderRadius: 10,
    padding: 15,
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 14,
    fontWeight: 600,
    cursor: "pointer",
    transition: "all 0.25s ease",
  },
  btnCancel: {
    backgroundColor: "#333",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#222",
    },
  },
  btnUpgrade: {
    background: "linear-gradient(135deg, #ff6b6b 0%, #ff5252 100%)",
    color: "white",
    boxShadow: "0 5px 15px rgba(255, 107, 107, 0.3)",
    position: "relative",
    overflow: "hidden",
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: "0 8px 20px rgba(255, 107, 107, 0.4)",
    },
    "&:after": {
      content: '""',
      position: "absolute",
      top: "-50%",
      left: "-50%",
      width: "200%",
      height: "200%",
      background:
        "radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%)",
      transition: "all 0.5s ease-out",
      opacity: 0,
    },
    "&:hover:after": {
      opacity: 1,
    },
  },
  privacyNote: {
    fontSize: 11,
    color: "#888",
    textAlign: "center",
    marginTop: 15,
  },
}));
