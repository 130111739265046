import axios from "axios";

export const fetchCtaVersions = async (
  widgetLabel: string,
  setCtaVersion: (version: string) => void
) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/cta/${widgetLabel}`
    );
    const ctas = response.data;
    // let totalWeight = ctas.reduce(
    //   (sum: number, cta: any) => sum + cta.weight,
    //   0
    // );
    // if (totalWeight <= 0) {
    //   totalWeight = ctas.length;
    //   ctas.forEach((cta: any) => (cta.weight = 1));
    // }
    // const randomNum = Math.random() * totalWeight;
    // let cumulative = 0;
    // let chosenVersion = ctas[ctas.length - 1].versionIdentifier;
    // for (const cta of ctas) {
    //   cumulative += cta.weight;
    //   if (randomNum < cumulative) {
    //     chosenVersion = cta.versionIdentifier;
    //     break;
    //   }
    // }

    // Simple equal distribution (50/50 for 2 versions, 1/N for N versions)
    const randomIndex = Math.floor(Math.random() * ctas.length);
    const chosenVersion = ctas[randomIndex].versionIdentifier;

    setCtaVersion(chosenVersion);
  } catch (error) {
    console.error("Error fetching CTAs", error);
  }
};

export const postCtaView = async (
  widgetLabel: string,
  versionIdentifier: string
) => {
  try {
    await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/cta/view`,
      { widgetLabel, versionIdentifier },
      { withCredentials: true }
    );
  } catch (error) {
    console.error("Error posting CTA view:", error);
  }
};

export const postCtaConversion = async (
  widgetLabel: string,
  versionIdentifier: string
) => {
  try {
    await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/cta/conversion`,
      { widgetLabel, versionIdentifier },
      { withCredentials: true }
    );
  } catch (error) {
    console.error("Error posting CTA conversion:", error);
  }
};
//
