import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  modalContainer: {
    backgroundColor: "#fff",
    width: 520,
    borderRadius: 15,
    overflow: "hidden",
    boxShadow: "0 25px 50px rgba(0, 0, 0, 0.2)",
    fontFamily: "'Poppins', sans-serif",
    position: "relative",
  },
  modalHeader: {
    backgroundColor: "#333",
    color: "#fff",
    padding: "20px 30px",
    position: "relative",
    overflow: "hidden",
  },
  headerPattern: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(135deg, rgba(255, 107, 107, 0.3) 0%, rgba(255, 255, 255, 0) 100%)",
    zIndex: 1,
  },
  modalTitle: {
    position: "relative",
    fontSize: 20,
    fontWeight: 700,
    margin: 0,
    zIndex: 2,
    display: "flex",
    alignItems: "center",
  },
  titleIcon: {
    width: 24,
    height: 24,
    backgroundColor: "#ff6b6b",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 12,
    fontSize: 14,
  },

  // Toggle
  toggleContainer: {
    display: "flex",
    top: 25,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    zIndex: 10,
  },
  toggleSwitch: {
    width: 80,
    height: 40,
    backgroundColor: "#f0f0f0",
    borderRadius: 20,
    padding: 5,
    display: "flex",
    alignItems: "center",
    position: "relative",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
  },
  toggleKnob: {
    width: 30,
    height: 30,
    backgroundColor: "#fff",
    borderRadius: "50%",
    position: "absolute",
    top: 5,
    left: 5,
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
    transition: "all 0.3s ease",
  },
  toggleLabels: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    fontSize: 11,
    fontWeight: 600,
    padding: "0 10px",
  },
  toggleOn: {
    color: "#ccc",
  },
  toggleOff: {
    color: "#666",
  },
  premiumLock: {
    position: "absolute",
    top: -10,
    right: -10,
    width: 24,
    height: 24,
    backgroundColor: "#ff6b6b",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontSize: 12,
    boxShadow: "0 2px 5px rgba(255, 107, 107, 0.3)",
  },

  // Body
  modalBody: {
    padding: "40px 30px 30px",
  },
  modalMessage: {
    fontSize: 16,
    color: "#333",
    margin: "0 0 20px",
    textAlign: "center",
    fontWeight: 500,
  },

  contentBenefits: {
    display: "flex",
    gap: 15,
    marginBottom: 25,
    flexWrap: "wrap",
  },
  benefitCard: {
    flex: 1,
    minWidth: 140,
    backgroundColor: "#f9f9f9",
    borderRadius: 10,
    padding: 15,
    textAlign: "center",
    transition: "all 0.3s ease",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.05)",
    },
  },
  benefitIcon: {
    fontSize: 28,
    marginBottom: 10,
  },
  benefitTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: "#444",
    margin: 0,
  },

  socialProof: {
    backgroundColor: "#f8f8f8",
    borderRadius: 10,
    padding: 15,
    marginBottom: 25,
    display: "flex",
    alignItems: "center",
  },
  usersAvatar: {
    position: "relative",
    width: 50,
    height: 40,
    marginRight: 15,
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: "50%",
    backgroundColor: "#ddd",
    position: "absolute",
    border: "2px solid white",
  },
  avatar1: {
    left: 0,
    top: 0,
    backgroundColor: "#ff6b6b",
    zIndex: 3,
  },
  avatar2: {
    left: 15,
    top: 0,
    backgroundColor: "#4caf50",
    zIndex: 2,
  },
  avatar3: {
    left: 30,
    top: 0,
    backgroundColor: "#2196f3",
    zIndex: 1,
  },
  socialText: {
    flex: 1,
    fontSize: 13,
    color: "#666",
  },
  socialHighlight: {
    fontWeight: 600,
    color: "#333",
  },

  modalActions: {
    display: "flex",
    gap: 15,
  },
  btn: {
    flex: 1,
    border: "none",
    borderRadius: 8,
    padding: 14,
    fontFamily: "'Poppins', sans-serif",
    fontSize: 14,
    fontWeight: 600,
    cursor: "pointer",
    transition: "all 0.3s ease",
  },
  btnCancel: {
    backgroundColor: "#eee",
    color: "#666",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
  btnUpgrade: {
    backgroundColor: "#ff6b6b",
    color: "#fff",
    boxShadow: "0 5px 15px rgba(255, 107, 107, 0.2)",
    "&:hover": {
      backgroundColor: "#ff5252",
      transform: "translateY(-3px)",
      boxShadow: "0 8px 20px rgba(255, 107, 107, 0.3)",
    },
  },
  // Simple appear animation if you want:
  "@keyframes modalAppear": {
    "0%": {
      opacity: 0,
      transform: "translateY(20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
}));
