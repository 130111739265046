"use client";

import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";

import { useStyles } from "./styles";
import { fetchCtaVersions, postCtaConversion, postCtaView } from "utils/cta";
import { useAppSelector, useAppDispatch } from "redux/store";
import { updateSafeSearch } from "redux/actions";
import SafeSearchUpgradeModalA from "components/common/PaywallCTA/SafeSearchPaywall/PaywallA";
import SafeSearchUpgradeModalB from "components/common/PaywallCTA/SafeSearchPaywall/PaywallB";

interface SafeSearchToggleProps {
  size?: "small" | "medium";
  hideLabel?: boolean;
}

const SafeSearchToggle: React.FC<SafeSearchToggleProps> = ({
  size = "small",
  hideLabel = false,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const authLoading = useAppSelector((state) => state.user.authLoading);

  const isSubscribed = Boolean(user && user.subscriptionType !== "free");
  const safeSearchEnabled = user?.safeSearch ?? true;

  const [showModal, setShowModal] = useState(false);
  const [ctaVersion, setCtaVersion] = useState("A");

  useEffect(() => {
    if (!isSubscribed) {
      fetchCtaVersions("for_you_page_safe_search_click", setCtaVersion);
    }
  }, [user, isSubscribed]);

  const handleUpgrade = async () => {
    await postCtaConversion("for_you_page_safe_search_click", ctaVersion);
  };

  const handleToggleSafeSearch = async () => {
    if (!isSubscribed && safeSearchEnabled) {
      setShowModal(true);
      await postCtaView("for_you_page_safe_search_click", ctaVersion);
      return;
    }
    dispatch(updateSafeSearch(!safeSearchEnabled));
  };

  if (authLoading) return null;

  return (
    <div className={classes.container}>
      {!hideLabel && <p className={classes.label}>Safe Search</p>}
      <Switch
        color="primary"
        size={size}
        checked={safeSearchEnabled}
        onChange={handleToggleSafeSearch}
        inputProps={{ "aria-label": "Safe Search Toggle" }}
      />

      {showModal &&
        (ctaVersion === "A" ? (
          <SafeSearchUpgradeModalA
            onClose={() => setShowModal(false)}
            onUpgrade={handleUpgrade}
          />
        ) : (
          <SafeSearchUpgradeModalB
            onClose={() => setShowModal(false)}
            onUpgrade={handleUpgrade}
          />
        ))}
    </div>
  );
};

export default SafeSearchToggle;
