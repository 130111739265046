"use client";

import React, { FC, useState } from "react";
import { useRouter } from "next/navigation";
import { useStyles } from "./styles";

interface SafeSearchModalProps {
  onClose: () => void;
  onUpgrade: () => void;
}

const SafeSearchModal: FC<SafeSearchModalProps> = ({ onClose, onUpgrade }) => {
  const { classes } = useStyles();
  const router = useRouter();
  const [isToggleOn, setIsToggleOn] = useState(true);

  const handleToggle = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsToggleOn((prev) => !prev);
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClose();
  };

  const handleUpgradeClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onUpgrade();
    router.push("/subscribe");
  };

  const handleOverlayClick = () => {
    onClose();
  };

  const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <div className={classes.modalOverlay} onClick={handleOverlayClick}>
      <div className={classes.modalContainer} onClick={handleContainerClick}>
        <div className={classes.modalHeader}>
          <div className={classes.headerPattern} />
          <h2 className={classes.modalTitle}>
            <span className={classes.titleIcon}>🔒</span>
            Premium Feature: Disable Safe Search
          </h2>
        </div>

        <div className={classes.toggleContainer}>
          <div className={classes.toggleSwitch} onClick={handleToggle}>
            <div className={classes.toggleKnob} />
            <div className={classes.toggleLabels}>
              <span className={classes.toggleOn}>ON</span>
              <span className={classes.toggleOff}>OFF</span>
            </div>
            <div className={classes.premiumLock}>🔒</div>
          </div>
        </div>

        <div className={classes.modalBody}>
          <p className={classes.modalMessage}>
            Subscribe to unlock unrestricted content generation
          </p>

          <div className={classes.contentBenefits}>
            <div className={classes.benefitCard}>
              <div className={classes.benefitIcon}>🔍</div>
              <p className={classes.benefitTitle}>Unrestricted Content</p>
            </div>
            <div className={classes.benefitCard}>
              <div className={classes.benefitIcon}>🖼️</div>
              <p className={classes.benefitTitle}>All Image Styles</p>
            </div>
            <div className={classes.benefitCard}>
              <div className={classes.benefitIcon}>⚡</div>
              <p className={classes.benefitTitle}>Priority Rendering</p>
            </div>
          </div>

          <div className={classes.socialProof}>
            <div className={classes.usersAvatar}>
              <div className={`${classes.avatar} ${classes.avatar1}`} />
              <div className={`${classes.avatar} ${classes.avatar2}`} />
              <div className={`${classes.avatar} ${classes.avatar3}`} />
            </div>
            <div className={classes.socialText}>
              <span className={classes.socialHighlight}>
                87% of premium users
              </span>{" "}
              report creating better, more diverse images with safe search
              disabled.
            </div>
          </div>

          <div className={classes.modalActions}>
            <button
              className={`${classes.btn} ${classes.btnCancel}`}
              onClick={handleCancel}
            >
              CANCEL
            </button>
            <button
              className={`${classes.btn} ${classes.btnUpgrade}`}
              onClick={handleUpgradeClick}
            >
              UPGRADE NOW
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafeSearchModal;
