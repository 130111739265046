import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    margin: 0,
    marginRight: 8,
    fontSize: 14,
    color: "#333",
    fontFamily: "'Montserrat', sans-serif",
  },
}));
