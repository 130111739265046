"use client";

import React, { FC, MouseEvent } from "react";
import { useRouter } from "next/navigation";
import { useStyles } from "./styles";

interface SafeSearchUpgradeModalAProps {
  onClose: () => void;
  onUpgrade: () => void;
}

const SafeSearchUpgradeModalA: FC<SafeSearchUpgradeModalAProps> = ({
  onClose,
  onUpgrade,
}) => {
  const router = useRouter();
  const { classes } = useStyles();

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClose();
  };

  const handleUpgrade = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onUpgrade();
    router.push("/subscribe");
  };

  const stopPropagation = (e: MouseEvent<HTMLDivElement>) =>
    e.stopPropagation();

  return (
    <div className={classes.modalOverlay}>
      <div className={classes.modalContainer} onClick={stopPropagation}>
        <div className={classes.modalBackground} />
        <div className={classes.modalContent}>
          <div className={classes.modalHeader}>
            <div className={classes.lockAnimation}>
              <div className={classes.lockBody} />
              <div className={`${classes.sparkle} ${classes.sparkle1}`} />
              <div className={`${classes.sparkle} ${classes.sparkle2}`} />
              <div className={`${classes.sparkle} ${classes.sparkle3}`} />
            </div>
            <h2 className={classes.modalTitle}>Premium Feature</h2>
            <p className={classes.modalSubtitle}>
              Unlock unrestricted content creation
            </p>
            <div className={classes.modalDivider} />
          </div>

          <div className={classes.modalBody}>
            <div className={classes.featureHighlight}>
              <span className={classes.featureIcon}>🔍</span>
              <span className={classes.featureText}>
                Turn off safe search to access all content categories{" "}
                <span className={classes.premiumBadge}>PREMIUM</span>
              </span>
            </div>

            <p className={classes.modalMessage}>
              You must have a{" "}
              <span className={classes.highlightedText}>
                Premium subscription
              </span>{" "}
              to disable safe search and unlock the full creative potential of
              our AI image generator.
            </p>

            <div className={classes.modalActions}>
              <button
                className={`${classes.btn} ${classes.btnCancel}`}
                onClick={handleCancel}
              >
                CANCEL
              </button>
              <button
                className={`${classes.btn} ${classes.btnUpgrade}`}
                onClick={handleUpgrade}
              >
                UPGRADE NOW
              </button>
            </div>

            <p className={classes.privacyNote}>
              Your privacy is important to us. All billing is secure and
              discreet.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafeSearchUpgradeModalA;
